.details-btns {
    display: flex;
    width: 12rem!important;
    text-align: center!important;
    color: #000;
}

.details-btns:hover {
    background: linear-gradient(cadetblue, red);
}

@media print {
    .details-table {
        display: flex;
        /* display: flex;
        justify-content: center;
        margin-right: -5rem!important; */
        /* margin-left: 27rem!important; */
        /* overflow: hidden; */
        margin-left: 12rem!important;
        width: 80%;
    }

    .gif-logo-container {
        margin-left: 8.7rem!important;

    }

    .table-table {
        display: flex;

        /* display: inline-block; */
        border: solid #000 !important;
        border-width: 2px !important;
        color: #000;
        font-weight: bolder;
        width: 80%;
        /* left: 17rem!important; */
        margin-right: -2rem!important;
        margin-left: 9.3rem!important;
    }

    .back-btn-container,
    .print-btn-container{
        display: none!important;
    }
    
    .reports-table-table {
        border: solid #000 !important;
        border-width: 2px !important;
        /* margin-left: 1rem; */
        color: #000;
        font-weight: bolder;
    }

    .table-table tr th, 
    .table-table tr td {
        border: 2px solid #000 !important;
        /* border-width: 1px !important; */
        font-weight: bold;
        font-size: 15px;
        color: #000;

    }
}