.general-sidebar-container {
    /* position: -webkit-sticky; */
    position: fixed;
    /* display: flex; */
    /* justify-content: center; */
    /* padding-left: 1.3rem; */
    width: 15rem;
    /* height: max-content!important; */
    top: .05px;
    bottom: 0px;
    overflow-y: scroll;
    /* font-family: 'Lucida Calligraphy'; */
}



.sidebar-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.nav-menu-item {
    display: flex;
    height: 2rem;
}


.link_hover:hover{
    color: #dba620;
    font-weight: 600;
    font-style: italic;
    transition: 3s  ease-in 25s linear;
    transform: scaleX("7px");
    font-size: 13px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5), 0 4px 7px 0 rgba(0, 0, 0, 0.65);
    padding: 5px 7px;
    border-radius: 5px;
    border-bottom: 2px solid ghostwhite;
    text-decoration: none;
}

.link_hover{
    color: ghostwhite;
    font-weight: 500;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 17px;
}

.gradient {
    background: linear-gradient(rgba(1, 15, 32, 0.734), rgba(14, 52, 14, 0.3));
    color: black;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 5px 0 rgba(0, 0, 0, 0.65);
    height: 15vh!important;
}

.main-sidebar-gradient {
    background: linear-gradient(rgba(1, 15, 32, 0.734), rgba(14, 52, 14, 0.3));
    color: black;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 5px 0 rgba(0, 0, 0, 0.65);
    height: 10vh!important;
}

.sidebar-footer-container {
    display: flex;
    /* top: 90vh!important; */
    position: static;
    width: 15rem;
   
    /* z-index: 1; */
}