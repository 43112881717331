
.folio-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-right: -12rem!important;
    margin-top: 2rem;
}

.folio-title {
    font-size: 1.15rem;
}

@media print {
    .folio-container {
        display: flex;
        width: 100vw;
        justify-content: center;
        margin-top: .5rem;
        font-weight: bolder;
        padding-right: 1.7rem;
        padding-left: 1.7rem;
    }
    .receipt-header {
        justify-content: center;
        display: flex;
        margin-left: 4.3rem;
    }

    .companys-address-and-name {
        justify-content: center!important;
        display: flex;
        align-items: center!important;
        width: 90%!important;
        text-align: center!important;
    }

    .f-and-b-table tr td {
        font-weight: bolder;
        font-size: 1.6rem!important;
        font-family: serif;
        color: #000;
    }
    .receipt-logo {
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        color: #000;
        font-weight: bolder;
        margin-right: 3rem;
        font-size: 1.65rem!important;
    }

    .folio-title {
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        color: #000;
        font-weight: bolder;
        margin-right: 4.5rem;
        font-family: serif;
        font-size: 1.65rem!important;
    }
    .folio-timestamp {
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        color: #000;
        font-weight: bolder;
        margin-right: 4.3rem;
        font-family: serif;
        font-size: 2.3rem!important;
    }
    .folio-btns,
    .print-btn-container {
        display: none!important;
    }

    
    .signature-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 1.6rem!important;
        font-weight: bolder;
        font-family: serif;
        color: #000;

    }

    .table-table tr th, 
    .table-table tr td {
        border: thin #fff;
        border-width: 0px 0px 0px 0px;
        /* font-size: 13px; */

    }

    .table-table tr th,
    .table-table tr td {
        font-size: 1.5rem!important;
        font-weight: bolder!important;
    }
}