

/* @media screen { */
    .reg-table
    {
        
        /* font-family: verdana,arial,sans-serif; */
        /* background-color: #fff; */
        display: flex;
        justify-content: center;
        font-size: 15px;
        color: #000;
        border-width: 1px;
        border-color: #666666;
        border-collapse: collapse;
        margin-left: 2rem;
        /* width: 100vw; */
    }
    .reg-table th, 
    .reg-table td {
        border: 1px solid #000 !important;
        font-size: 15px;

    }
    .other-group-reg-details,
    .group-logo-container {
        margin-left: 2rem;
    }
    .other-group-reg-details {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .other-group-reg-details section article {
        display: flex;
        width: 100vw;
        justify-content: center;
    }
 /* }; */

 @media print{
    .reg-header{
        display: flex;
        width: 100vw;
        /* margin-right: -15rem; */
        margin-left: 2rem;
        justify-content: center;
    }
    .individual-reg-container {
        display: flex;
        width: 100vw;
        /* margin-left: 15rem; */
        justify-content: center;
    }

    .individual-reg-container div{
        display: flex;
        width: 100vw;
        flex-direction: column;
        /* margin-left: -13rem!important; */
        
    }

    .reg-card-title{
        display: flex!important;
        justify-content: center!important;

        /* margin-left: -1rem; */
    }
    .reg-logo {
        display: flex!important;
        justify-content: center!important;

        margin-left: -8rem;

    }

    .group-logo-container {
        margin-left: -7rem;
    }

    .signatures > span {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 13rem;
    }

    .other-group-reg-details {
        /* margin-left: -7rem; */
        flex-direction: column;
    }

    .reg-container {
        /* background-color: rgb(77, 107, 119); */
        left: 0%!important;
        right: 0%;
    }

    .reg-table {
       /* font-family: verdana,arial,sans-serif; */
       display: flex;
       align-self: center;
       justify-content: center;
       align-items: center;
       /* background-color: red; */
       font-size: 17px;
       color: #000;
       border: 1px solid #000 !important;
       width: 700px;
       left: 0%;
       right: 0%;
    }
    .reg-table th, 
    .reg-table td {
        border: 1px solid #000 !important;
        font-size: 15px;

    }

    
}

@-moz-document url-prefix() {
    .reg-table {
      position: static !important;
    }
  }