.password-input {
    border: none!important;
    background-color: #fff!important;
    border-width: 0;
}

input {
    background-color: #fff;
}

.password-input:focus {
    border: none!important;
    background-color: #fff!important;
    border-width: 0;
}