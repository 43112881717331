
.table-table th, 
.table-table td {
    border: solid #000;
    border-width: 1px 1px 1px 1px;
    font-size: 13px;

}

.balance-table {
    float: right;
}


@media print{
    .tables-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 11rem!important;


        /* display: grid;
        width: 100vw;
        margin-left: -3rem!important;
        margin-right: 3rem!important; */
    }

    .frontdesk-table-container {
        display: table;
        flex-direction: column;
        /* width: 80vw; */
        margin-left: .5rem!important;
        margin-right: 5rem!important;
    }

    .frontdesk-logo-container {
        margin-left: 7.3rem!important;

    }
    

    .gif-logo-container {
        margin-left: 11rem!important;
    }

    .guest-bill-gif-logo-container {
        display: flex;
        flex-direction: column;
        margin-right: -6rem!important;
    }
  
    .back-btn-container,
    .bill-close-btn,
    .frontdesk-top-buttons,
    .frontdesk-total-count,
    .frontdesk-summary-title,
    .bill-print-btn {
        display: none!important;
    }

    .table-table {
        display: table!important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%!important;
        margin-left: 2rem!important;
        margin-right: 3rem!important;
    }

    .table-table tr th, 
    .table-table tr td {
        border: 2px solid #000 !important;
        font-size: 27px;
        color: #000;
        margin-right: 3rem!important;
    }

    .print-footer-address {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 2rem;
        margin-left: 5rem!important;

    }
}

@-moz-document url-prefix() {
    .reg-table {
      position: static !important;
    }
  }