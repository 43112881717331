.span-gif-container {
    border: 2px solid #1f2d3d;
    border-radius: 5px;
    background-color: #fff;
    width: 24rem;
    justify-content: space-between;
    z-index: 1;
}

.input-search{
    border: 0px none white!important;
    z-index: -1;

}

.input-search:focus {
    outline: none!important;
    border: 0 none white!important;

}