.guest-bill-table-table tr th, 
.guest-bill-table-table tr td {
    border: 1px solid #000 !important;
    /* border-width: 1px !important; */
    font-size: 15px;

}


@media print{
    .guest-bill-tables-container {
        display: flex;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        margin-left: 9rem!important;
        margin-right: 3rem!important;
    }

    .guest-bill-transaction-table,
    .guest-bill-accomodation-table{
        display: flex;
        flex-direction: column;
        margin-left: -8rem;
        justify-content: center;
        /* width: 100vw; */
        /* font-size: 2rem!important; */
    }

    .guest-bill-accomodation-table p,
    .guest-bill-transaction-table p {
        font-size: 2.2rem!important;
        margin-left: 2rem!important;
        display: flex;
        width: 100vw;

    } 

    .guest-bill-balance-table {
        align-self: center;
        display: flex!important;
        /* margin-right: 10rem!important; */
        justify-content: space-between!important;
        width: 100vw!important;
    }

    .guest-bill-disclaimer {
        display: flex!important;
        flex-direction: column;
        margin-left: -7rem;
    }

    .guest-bill-table-table thead tr th, 
    .guest-bill-table-table tbody tr td {
        border: 2px solid #000 !important;
        /* border-width: 1px !important; */
        font-size: 1.15rem!important;
        width: 100vw;

    }
}

@-moz-document url-prefix() {
    .reg-table {
      position: static !important;
    }
  }