.tray-container {
    /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.37), 0 5px 9px 0 rgba(0, 0, 0, 0.57); */

}

.tray-container > section {
    height: 70vh;
    width: 60vw;
    box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.37), 1px 5px 9px 1px rgba(0, 0, 0, 0.57);
    background: linear-gradient( rgb(68, 68, 116), rgb(170, 89, 89));
    margin-top: 4rem;
    margin-left: 15em;
    display: flex;
    justify-content: center;
    padding: 12px;
    border-radius: 10px;
}

.tray-container > div {
    background: linear-gradient( rgb(68, 68, 116), rgb(170, 89, 89));
    color: aliceblue;
    border-radius: 10px;

}


.payment-tray {
    border-radius: 10px;
    height: 55vh;
    /* width: 30vw; */
    box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.37), 1px 5px 9px 1px rgba(0, 0, 0, 0.57);
    background: linear-gradient( rgb(68, 68, 116), rgb(170, 89, 89));
    color: aliceblue;
    margin-top: 6rem;
    margin-left: 30rem;
    justify-content: center;
    padding-top: 1rem;
}

.payment-tray  section {
    /* justify-content: center; */
    /* margin-left: 12rem; */

}

.tray-container div section  {
    /* background: linear-gradient( rgb(68, 68, 116), rgb(170, 89, 89)); */
}

.btn-container button {
    box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.37), 1px 5px 9px 1px rgba(0, 0, 0, 0.57);
    margin-bottom: 1.5rem;
}

.footer-img {
    width: 120px;
    height: 120px;
    opacity: 0.5;
}