@import url('https://fonts.googleapis.com/css?family=Sansita:light,bold,italic&subset=latin,latin-ext');


body {
  margin: 0;
  padding: 0%;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  */
  font-family: "Sansita", serif, Georgia;
  /* font-family: serif, Georgia,"Lucida Calligraphy"; */

  position: sticky;
  /* top: 0%;
  bottom: 0%; */
  left: 0%;
  right: 0%;
  /* overflow: auto; */
}

code {
  font-family: "Sansita", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img {
  /* width: 100%; */
  object-fit: contain;
}


hr {
  display: flex;
  width: 80vw;
  background-color: #d6e1eba9;
  border-width: '2px'

}

.sidebar_hr {
  width: 100%;
  background-color: #dba620;
  margin-top: 2px
}

.confirm_modal_hr {
  width: 100%;
  background-color: steelblue;
  margin-top: 2px
}

.col_container {
  display: table; /* Make the container element behave like a table */
  width: 100%; /* Set full-width to expand the whole page */
}

.col_cells{
  display: table-cell; /* Make elements inside the container behave like table cells */
}

.no-record {
  display: flex;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.table-actions-btn {
  border: 2px red solid!important;
  background: linear-gradient(steelblue, #1f2d3d);
}

.table-actions-btn:hover {
  background: red;
  border: 2px steelblue solid!important;

}

.frontdesk-buttons {
  border: 2px #fff solid!important;
  background: linear-gradient(#fff, #fff);
  font-weight: bold;
}

.frontdesk-buttons:hover {
  background: linear-gradient(#dba620, #205386);
  border: 2px #fff solid!important;

}

.ghost-white-line {
  border-bottom-width: 2px;
  border-bottom-color: ghostwhite;
  border-bottom-style: solid;
  width: 80vw
}

.button {
  font-family: Georgia, 'Times New Roman', Times, serif!important;
  border-radius: 6px;
}

@media print {
  .module-summary,
  .filter,
  .print-side-navbar {
    display: none!important;    
  }

}