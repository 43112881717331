.end-of-day-container {
    /* display: flex; */
    margin-top: -3.5rem;
    padding-top: 2rem;
    width: 100vw;
    height: 100vh;
    background-color: black;

}

.loading-text {
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
}

.gif-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    background-color: black;
}

