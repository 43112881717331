.module_sidebar_container {
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5), 0 5px 9px 0 rgba(0, 0, 0, 0.75); */
    border-left: 2px solid #dba620;
    /* border-top: 2px solid #dba620; */
    border-bottom: 2px solid #dba620;
    /* height: 100vh; */
    width: 15rem;
    position: fixed;
    top: 0px;
    bottom: 0px;
    /* background: linear-gradient( rgba(0, 200, 120, 0.5)); */
    overflow-y: auto;
    background-color: #1f2d3d;
    justify-content: space-between;
    z-index: 1;
}

.module_sidebar_container .sidebar-footer-container {
    height: 1vh;
    overflow: visible;
    margin-bottom: 5rem;
}

.fa_chevron_hide{
    display: none;
}