.results-container {
    flex-direction: column;
    /* height: auto; */
}

.modules-status-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-left: 4rem;
    padding-right: 4rem;
}
.result-title {
    font-size: 30px;
    font-weight: bold;
}
.modules-status {
    width: 100%;
}

.modules-title-icon {
    justify-content: space-between;
    box-shadow: 0 2px 3px 0 rgba(150, 200, 0, 0.6), 0 4px 6px 0 rgba(100, 0, 120, 0.8);
    border-radius: 7px;
    font-size: 23px;
    font-weight: bold;
}

.status-message {
    font-size: 20px;
}