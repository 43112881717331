.trial-balance-table-container {
    flex-direction: column;
    margin-right: .5rem;
    width: 95%
}

.trial-balance-table-table tr th, 
.trial-balance-table-table tr td {
    border: solid #000;
    border-width: 1px 1px 1px 1px;
    font-size: 13px;

}

@media print {
    .trial-balance-table-container {
        display: grid;
        width: 90vw;
        margin-left: 4rem!important;
        margin-right: -2.7rem!important;
    }


    .trial-balance-header {
        margin-left: 2rem!important;
    }

    .trial-balance-table-table tr th, 
    .trial-balance-table-table tr td {
        border: 2px solid #000!important;
        border-width: 1px 1px 1px 1px!important;
        font-size: 14px!important;
        color: #000!important;
    }
}