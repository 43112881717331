tfoot td {
    border: solid #000 !important;
    border-width: 1px !important;
    /* margin-left: -7rem; */
    /* color: #000; */
    font-weight: 500;
}
.managers-flash-table-table {
    /* display: inline-block; */
    border: solid #000 !important;
    border-width: 2px !important;
    /* margin-left: -2rem; */
    /* margin-right: 7rem!important; */
    color: #000;
    width: 100%!important;
    font-weight: bolder;
}
.managers-flash-table-table tr th, 
.managers-flash-table-table tr td {
    border: 1px solid #000 !important;
    /* border-width: 1px !important; */
    font-weight: bold;
    color: #000;
    font-size: 15px!important;
}

.managers-flash-table-table thead tr th, 
.managers-flash-table-table thead tr td {
    border: 1px solid #000 !important;
    /* border-width: 1px !important; */
    font-weight: bold;
    color: #fff;
    font-size: 15px!important;
}

@media print{
    .managers-flash-table-container {
        display: grid;
        flex-flow: nowrap;
        width: 90%!important;
        margin-left: 1rem!important;
        margin-right: -3rem!important;
        font-family: serif;
        font-size: 12px;
    }
    .managers-flash-body {
        font-family: serif;
        font-size: 12px!important;
    }
    .last-year-column {
        width: 100%!important;
    }
    
    .pagination-buttons,
    .back-btn,
    .print-btn-container {
        display: none;
    }

    .reports-table-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 8rem!important;
        margin-right: 22rem!important;
    }

    .managers-flash-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        /* margin-right: 2rem;
        margin-left: -5rem; */
    }

    .managers-flash-notes {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
    }
    
    .managers-flash-table-table {
        /* display: inline-block; */
        border: solid #000 !important;
        border-width: 2px !important;
        /* margin-left: -2rem; */
        /* margin-right: 7rem!important; */
        color: #000;
        width: 100%!important;
        font-weight: bolder;
    }

    tfoot td {
        border: solid #000 !important;
        border-width: 2px !important;
        /* margin-left: -7rem; */
        color: #000;
        font-weight: bolder;
    }
    .managers-flash-table-table thead tr th, 
    .managers-flash-table-table thead tr td {
        border: 1px solid #000 !important;
        /* border-width: 1px !important; */
        font-weight: bold;
        color: #000;
        font-size: 18px!important;
    }
    .managers-flash-table-table tr th, 
    .managers-flash-table-table tr td {
        border: 1px solid #000 !important;
        /* border-width: 1px !important; */
        font-weight: bold;
        color: #000;
        font-size: 16px!important;
    }
}