.input:focus {
    outline: none!important;
    border: none!important;
    border-color: #fff!important;
}

.details-table tr th,
.details-table tr td {
    border: solid #000;
    border-width: 1px 1px 1px 1px;
    font-size: 13px;
}

@media print {
    .reservation-title {
        justify-content: center;
        align-self: center;
        align-items: center;
        display: flex;
        width: 100vw;
        font-size: 3rem;
        margin-left: 9rem;
    }
    .sub-reservation-title {
        display: none!important;
    }

    .future-arrivals-headers th { 
        font-size: 7rem!important;
        color: black!important;
        font-family: serif;
        font-size: 35px;
    }
    .details-table{
        flex-direction: column;
        width: 90vw!important;
        /* display: flex; */
        /* margin-left: -2rem!important; */
    }

    .details-table tr th,
    .details-table tr td {
        border-width: 1px 1px 1px 1px;
        border: 2px solid #000 !important;
        font-size: 27px;
        color: #000;

    }

    .future-arrivals-container {
        display: flex;
        flex-direction: column;
        margin-left: 4vw;
        margin-right: 35vw!important;
        /* padding-right: 25vw!important; */
    }

    .future-logo-container {
        display: flex;
        width: 100%;
        margin-left: 18rem;
        justify-content: center!important;
        align-items: center;
        align-self: center;
    }
   
}