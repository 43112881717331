
.home_body {
    background-image: url("../assets/img/hotel-room-beautiful-orange.jpg"), url("../assets/img/bkg12.jpg"); 
    background-size:cover; 
    /* background-position:center; 
    background-repeat:no-repeat; */
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat;
    background-color: #1f2d3d;
    background-blend-mode: multiply;
    /* 1f2d3d */
};


img {
    object-fit: contain;
}

.gif_active_in_home_body {
    background-color: #000;
}

.second_gif_active_in_home_body {
    background-color: #000;
}

.indicator_container_style {
    display: flex;
    flex-direction: column; 
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.gif_style {
    display: flex; 
    width: 50%;
    height: 50%
}

.gif_text {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
    color: turquoise;
}

.box_shadow {
    box-shadow: 0 3px 5px 0 rgba(0,0,0, 0.9), 0 7px 9px 0 rgba(0,0,0, 0.9);
    border-radius: 20px;
}

.header-container {
    background-color: #1f2d3d;
    z-index: 10;
    position: fixed; 
    width: 100%; 
    top: 0;
    border-bottom: 1px solid #dba620;
}